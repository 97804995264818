import { RequestListItem } from "@/models/request/list/RequestListItem";
import { Request, RequestState } from "@/models/request/Request";
import { RequestDetails } from "@/models/request/RequestDetails";
import { RequestFormConfig } from "@/models/request/RequestFormConfig";
import { ValuesData } from "@/models/ValuesData";
import { RequestInfo } from "@/models/request/RequestInfo";
import api from ".";
import { AxiosResponse } from "axios";
import { QuestionAnswer } from "@/models/QuestionAnswer";
import { RequestFormValue } from "@/models/request/RequestFormValue";
import { RequestAdminDataUpdateDTO } from "@/models/request/RequestAdminDataUpdateDTO";
import { RequestCopyTermsDTO } from "@/models/request/RequestCopyTermsDTO";
import { MarketRequestState } from "@/store/modules/marketRequest";
import { MarketRequest } from "@/models/request/MarketRequest";
import { RequestGridConfig } from "@/models/request/RequestGridConfig";
import { ImportResult } from "@/models/request/ImportResult";
import { DataSourceRequest } from "@/models/DataSourceRequest";
import { DataSourceResult } from "@/models/DataSourceResult";
import { CustomerStatusChangedEmail } from "@/models/request/CustomerStatusChangedEmail";
import { RequestAdminDetailsUpdateDTO } from "@/models/request/RequestAdminDetailsUpdateDTO";
import { FreezeModelsResult } from "@/models/FreezeModelsResult";

function getRequestsByState(state: RequestState): Promise<RequestListItem[]> {
    return api.get("requests", { params: { state } }).then((r) => r.data);
}

function getRequestDataSource(
    requestState?: string | null,
    marketId?: number | null,
    marketRequestState?: string | null,
    additionalData?: DataSourceRequest | null
): Promise<DataSourceResult<RequestListItem>> {
    return api
        .post("requests/data-source", {
            requestState,
            marketId,
            marketRequestState,
            additionalData
        })
        .then((r) => r.data);
}

function getRequestsByMarket(
    marketId: number,
    marketRequestState?: MarketRequestState
): Promise<RequestListItem[]> {
    return api
        .get<RequestListItem[]>(`requests/market/${marketId}`, {
            params: { marketRequestState }
        })
        .then((r) => r.data);
}

function getRequests(): Promise<RequestListItem[]> {
    return api.get("requests").then((r) => r.data);
}

function getRequestInfoById(requestId: number): Promise<RequestDetails> {
    return api.get("requests/details/" + requestId).then((r) => r.data);
}

function getMarketRequestData(
    marketRequestId: number,
    requestId: number
): Promise<ValuesData> {
    return api
        .get(`requests/values/${requestId}/market-request/${marketRequestId}`)
        .then((r) => r.data);
}

function loadMarketRequestDetails(
    marketRequestId: number
): Promise<MarketRequest> {
    return api
        .get(`requests/details/market-request/${marketRequestId}`)
        .then((r) => r.data);
}

function getRequestInfo(marketId = 0): Promise<RequestInfo> {
    return api
        .get("requests/info", { params: { marketId } })
        .then((r) => r.data);
}

function getConfigByRequestType(
    requestTypeId: number
): Promise<RequestFormConfig> {
    return api.get(`requests/form-config/${requestTypeId}`).then((r) => r.data);
}

function createRequest(request: Request): Promise<number> {
    return api.post("requests", request).then((r) => r.data);
}

function saveRequest(request: Request): Promise<void> {
    return api.put(`requests/${request.requestId}`, request);
}

function getRequestById(requestId: number): Promise<Request> {
    return api.get(`requests/${requestId}`).then((r) => r.data);
}

function deleteDraft(requestId: number): Promise<void> {
    return api.delete(`requests/draft/${requestId}`);
}

function sendRequest(requestId: number): Promise<void> {
    return api.post(`requests/${requestId}/send`);
}

function finalizeRequest(requestId: number, message: string): Promise<void> {
    return api.post(`requests/${requestId}/finalize`, { message });
}

function freezeModelsForRequest(
    requestId: number
): Promise<FreezeModelsResult> {
    return api.post(`requests/${requestId}/freeze-models`).then((r) => r.data);
}

function uploadAttachment(
    marketId: number,
    attachment: File
): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("attachment", attachment);
    return api.post(`requests/${marketId}/attachments`, formData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    });
}

function deleteAttachment(
    marketId: number,
    attachmentId: number
): Promise<AxiosResponse> {
    return api.delete(`requests/${marketId}/attachments/${attachmentId}`);
}

function uploadMarketRequestAttachment(
    marketRequestId: number,
    attachment: File
): Promise<AxiosResponse> {
    const formData = new FormData();
    formData.append("attachment", attachment);
    return api.post(
        `requests/market-request/${marketRequestId}/attachment`,
        formData,
        {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        }
    );
}

function deleteMarketRequestAttachment(
    marketRequestId: number,
    attachmentId: number
): Promise<AxiosResponse> {
    return api.delete(
        `requests/market-request/${marketRequestId}/attachment/${attachmentId}`
    );
}

type MarketRequestValueSaveInfo = {
    answers: QuestionAnswer[];
    values: RequestFormValue[];
    addedVehicleIds: number[];
    removedVehicleIds: number[];
};

function saveMarketRequestValues(
    marketRequestId: number,
    info: MarketRequestValueSaveInfo
): Promise<void> {
    return api.patch(`requests/save/market-request/${marketRequestId}`, info);
}

function sendToHQMarketRequest(
    marketRequestId: number,
    message: string
): Promise<void> {
    return api.post(`requests/market-request/${marketRequestId}/send`, {
        message
    });
}

function approveMarketRequest(
    marketRequestId: number,
    message: string
): Promise<void> {
    return api.post(`requests/market-request/${marketRequestId}/approve`, {
        message
    });
}

function withdrawApprovalMarketRequest(
    marketRequestId: number,
    message: string
): Promise<void> {
    return api.post(
        `requests/market-request/${marketRequestId}/withdraw-approval`,
        {
            message
        }
    );
}

function sendMessageToHeadquartersAsync(
    marketRequestId: number,
    message: string
): Promise<void> {
    return api.post(
        `requests/market-request/${marketRequestId}/send-message-to-headquarters`,
        {
            message
        }
    );
}

function sendMessageToMarketUsersAsync(
    marketRequestId: number,
    message: string,
    selectedUserIds: number[] | null
): Promise<void> {
    return api.post(
        `requests/market-request/${marketRequestId}/send-message-to-market-users`,
        {
            message,
            selectedUserIds
        }
    );
}

function requestUpdateMarketRequest(
    marketRequestId: number,
    message: string
): Promise<void> {
    return api.post(
        `requests/market-request/${marketRequestId}/request-update`,
        {
            message
        }
    );
}

function exportRequest(
    requestId: number
): Promise<{ filename: string; data: Blob }> {
    return api
        .post(`requests/export/${requestId}`, null, { responseType: "blob" })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename="(.+)"/)[1]
            };
        });
}

function importRequest(
    marketRequestId: number,
    requestId: number,
    file: File
): Promise<ImportResult> {
    const formData = new FormData();
    formData.append("file", file);
    return api
        .post(
            `requests/import/${requestId}/market-request/${marketRequestId}/file`,
            formData,
            {
                headers: {
                    "Content-Type": "multipart/form-data"
                }
            }
        )
        .then((r) => r.data);
}

function cloneRequest(requestId: number): Promise<Request> {
    return api.post(`requests/clone/${requestId}`).then((r) => r.data);
}

function copyTerms(data: RequestCopyTermsDTO): Promise<void> {
    return api.post("requests/copy-terms", {
        sourceRequestId: data.sourceRequestId,
        destinationRequestId: data.destinationRequestId,
        marketId: data.marketId,
        supplyScenarioMapping: data.supplyScenarioMapping
    });
}

function updateRequestAdminData(
    data: RequestAdminDataUpdateDTO
): Promise<void> {
    return api.put(`requests/${data.requestId}/admin-data`, data);
}

function sendRequestStatusChangedMessage(
    email: CustomerStatusChangedEmail
): Promise<AxiosResponse> {
    const data = new FormData();

    data.append("requestId", email.requestId.toString());
    data.append("requestCustomerStatusName", email.requestCustomerStatusName);
    data.append("message", email.message);

    if (email.attachment) {
        data.append("attachment", email.attachment);
    }

    return api.post(`requests/admin-data/send-email`, data, {
        headers: { "Content-Type": "multipart/form-data" }
    });
}

function updateRequestAdminDetails(
    details: RequestAdminDetailsUpdateDTO
): Promise<void> {
    return api.put(`requests/${details.requestId}/admin-details`, details);
}

function getMarketRequestForRequestByMarket(
    requestId: number,
    marketId: number
): Promise<MarketRequest> {
    return api
        .get<MarketRequest>(`requests/${requestId}/market/${marketId}`)
        .then((r) => r.data);
}

function sendRequestUpdateMessage(
    requestId: number,
    message: string
): Promise<void> {
    return api.post(`requests/${requestId}/send-update-message`, { message });
}

function exportDashboard(
    requestGridConfig: RequestGridConfig
): Promise<{ filename: string; data: Blob }> {
    return api
        .post(`requests/export/dashboard`, requestGridConfig, {
            responseType: "blob"
        })
        .then((r) => {
            return {
                data: r.data,
                filename:
                    r.headers["content-disposition"].match(/filename="(.+)"/)[1]
            };
        });
}
export default {
    getRequestById,
    getRequests,
    getRequestsByState,
    getRequestsByMarket,
    getRequestInfoById,
    getMarketRequestData,
    getRequestInfo,
    getConfigByRequestType,
    createRequest,
    saveRequest,
    deleteDraft,
    uploadAttachment,
    deleteAttachment,
    uploadMarketRequestAttachment,
    deleteMarketRequestAttachment,
    sendRequest,
    saveMarketRequestValues,
    sendToHQMarketRequest,
    approveMarketRequest,
    withdrawApprovalMarketRequest,
    sendMessageToHeadquartersAsync,
    sendMessageToMarketUsersAsync,
    requestUpdateMarketRequest,
    finalizeRequest,
    freezeModelsForRequest,
    exportRequest,
    importRequest,
    cloneRequest,
    copyTerms,
    updateRequestAdminData,
    getMarketRequestForRequestByMarket,
    loadMarketRequestDetails,
    sendRequestUpdateMessage,
    exportDashboard,
    getRequestDataSource,
    sendRequestStatusChangedMessage,
    updateRequestAdminDetails
};
