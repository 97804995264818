import {
    MarketRequest,
    MarketRequestState as MarketState
} from "@/models/request/MarketRequest";
import { VehicleModel } from "@/models/VehicleModel";
import { QuestionAnswer } from "@/models/QuestionAnswer";
import { RequestDetails } from "@/models/request/RequestDetails";
import { ValuesData } from "@/models/ValuesData";
import { MutationTree } from "vuex";
import { MarketRequestState } from ".";
import { ImportResult } from "@/models/request/ImportResult";
import {
    defaultModelGridConfig,
    ModelGridConfig
} from "@/models/ModelGridConfig";
import { FreezeModelsResult } from "@/models/FreezeModelsResult";

export enum MarketRequestMutations {
    SetRequest = "setRequest",
    SetModelsById = "setModelsById",
    SetErrors = "setErrors",
    SetSelectedMarketRequest = "setSelectedMarketRequest",
    SetValuesData = "setValuesData",
    UpdateMarketRequestAnswer = "updateMarketRequestAnswer",
    SetModelGridConfig = "setModelGridConfig",
    UpdateModelGridConfig = "updateModelGridConfig",
    SetLoading = "setLoading",
    AddMarketRequestAttachment = "addMarketRequestAttachment",
    RemoveMarketRequestAttachment = "removeMarketRequestAttachment",
    ClearAttachmentInfo = "clearAttachmentInfo",
    RemoveModels = "removeModels",
    AddModels = "addModels",
    SetMarketRequestState = "setMarketRequestState",
    UpdateMarketRequest = "replaceMarketRequest",
    SetImportResult = "setImportResult",
    ResetModelGridConfig = "resetModelGridConfig",
    CleanModelsInfo = "cleanModelsInfo",
    SetFreezeModelsResult = "setFreezeModelsResult"
}

const mutations: MutationTree<MarketRequestState> = {
    [MarketRequestMutations.SetErrors](state, payload: string[] | null) {
        state.errors = payload;
    },
    [MarketRequestMutations.SetModelsById](
        state,
        payload: { id: number; models: VehicleModel[] }
    ) {
        const marketRequest = state.request?.marketRequests.find(
            (r) => r.marketRequestId === payload.id
        );
        if (marketRequest) {
            marketRequest.models = payload.models;
        }
    },
    [MarketRequestMutations.SetRequest](state, payload: RequestDetails) {
        state.request = payload;
    },
    [MarketRequestMutations.SetSelectedMarketRequest](
        state,
        payload: MarketRequest | null
    ) {
        state.newAttachments = [];
        state.removedAttachmentIds = [];
        if (payload) {
            const marketRequest = state.request?.marketRequests.find(
                (r) => r === payload
            );
            if (marketRequest) {
                state.selectedMarketRequest = payload;
            }
        } else {
            state.selectedMarketRequest = null;
        }
    },
    [MarketRequestMutations.SetValuesData](state, payload: ValuesData) {
        state.valuesData = payload;
    },
    [MarketRequestMutations.RemoveModels](state, payload: VehicleModel[]) {
        const ids = payload.map((m) => m.vehicleModelId);
        const newIds = ids.filter(
            (id) => !state.modelsInfo.removedModelIds.includes(id)
        );
        state.modelsInfo.removedModelIds.push(...newIds);
        state.modelsInfo.addedModelIds = state.modelsInfo.addedModelIds.filter(
            (id) => !ids.includes(id)
        );
    },
    [MarketRequestMutations.AddModels](state, payload: VehicleModel[]) {
        const ids = payload.map((m) => m.vehicleModelId);
        const newIds = ids.filter(
            (id) => !state.modelsInfo.addedModelIds.includes(id)
        );
        state.modelsInfo.addedModelIds.push(...newIds);
        state.modelsInfo.removedModelIds =
            state.modelsInfo.removedModelIds.filter((id) => !ids.includes(id));
    },
    [MarketRequestMutations.UpdateMarketRequestAnswer](
        state,
        payload: QuestionAnswer
    ) {
        if (state.selectedMarketRequest) {
            const answer = state.selectedMarketRequest.answers?.find(
                (a) => a.questionId === payload.questionId
            );
            if (answer) {
                answer.text = payload.text;
            } else if (!state.selectedMarketRequest.answers) {
                state.selectedMarketRequest.answers = [payload];
            } else {
                state.selectedMarketRequest.answers?.push(payload);
            }
        }
    },
    [MarketRequestMutations.SetModelGridConfig](
        state,
        payload: ModelGridConfig
    ) {
        state.modelGridConfig = payload;
    },
    [MarketRequestMutations.UpdateModelGridConfig](
        state,
        payload: Partial<ModelGridConfig>
    ) {
        state.modelGridConfig = { ...state.modelGridConfig, ...payload };
    },
    [MarketRequestMutations.SetLoading](state, payload: boolean) {
        state.isLoading = payload;
    },
    [MarketRequestMutations.AddMarketRequestAttachment](
        state,
        payload: File
    ): void {
        state.newAttachments.push(payload);
    },
    [MarketRequestMutations.RemoveMarketRequestAttachment](
        state,
        payload: string | number
    ): void {
        if (state.selectedMarketRequest) {
            const attachmentIndex =
                state.selectedMarketRequest.attachments?.findIndex(
                    (a) => a.id === payload
                );
            if (attachmentIndex != undefined && attachmentIndex > -1) {
                state.selectedMarketRequest.attachments?.splice(
                    attachmentIndex,
                    1
                );
                state.removedAttachmentIds.push(payload as number);
                return;
            }
        }
        const newAttachmentIndex = state.newAttachments.findIndex(
            (a) => a.name === payload
        );
        if (newAttachmentIndex > -1) {
            state.newAttachments.splice(newAttachmentIndex, 1);
        }
    },
    [MarketRequestMutations.ClearAttachmentInfo](state) {
        state.newAttachments = [];
        state.removedAttachmentIds = [];
    },
    [MarketRequestMutations.SetMarketRequestState](
        state,
        payload: MarketState
    ) {
        if (state.selectedMarketRequest) {
            state.selectedMarketRequest.state = payload;
        }
    },
    [MarketRequestMutations.UpdateMarketRequest](
        state,
        payload: MarketRequest
    ) {
        if (state.request) {
            const marketRequestIndex = state.request.marketRequests.findIndex(
                (mr) => mr.marketRequestId === payload.marketRequestId
            );
            state.request.marketRequests.splice(marketRequestIndex, 1, payload);
        }
    },
    [MarketRequestMutations.SetImportResult](
        state,
        payload: ImportResult
    ): void {
        state.importResult = payload;
    },
    [MarketRequestMutations.ResetModelGridConfig](state): void {
        state.modelGridConfig = defaultModelGridConfig();
    },
    [MarketRequestMutations.CleanModelsInfo](state): void {
        state.modelsInfo = {
            addedModelIds: [],
            removedModelIds: []
        };
    },
    [MarketRequestMutations.SetFreezeModelsResult](
        state,
        payload: FreezeModelsResult
    ): void {
        state.freezeModelsResult = payload;
    }
};

export default mutations;
